import React from "react";
import Container from "react-bootstrap/Container";
// Html Parser
import parse from 'html-react-parser';
// scss
import styles from "./content.module.scss";

const Content = (props) => {
  return (
    <>
      <section className={styles.contentWrapper}>
        <Container>
          <div className={styles.mainWrapper}>
            {
                props.serviceData?.sub_contents && (props.serviceData.sub_contents).map((list) => (
                    <div className={styles.contentBox} key={list.content}>
                      <div className={styles.content}>
                        {list.header && parse(list.header)}
                      </div>
                      <div className={`${styles.desc} mb-0`}>
                        {list.content && parse(list.content)}
                      </div>
                    </div>
                ))
            }
          </div>
        </Container>
      </section>
    </>
  );
}

export default Content;